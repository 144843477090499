<template>
    <div class="om-inside">
        <section class="section first full is-vcentered center">
            <div class="feature__cloud"></div>
            <AnimationBg/>
            <div class="container is-vcentered wrap">
                <div class="columns is-multiline is-mobile is-8 is-vcentered">
                    <div class="column center cid is-full-mobile" style="word-break: break-word;white-space: normal;">
                        <!-- Logo -->
                        <img alt="Entfalter Logo" src="../../../assets.entfalter_shared/img/logo.svg" class="logo"><br/>
                        <!-- Punchline -->
                        <h1 class="punchline ">Das Werkzeug für kluge Berufs- und Lebensentscheidungen</h1>
                        <!-- Intro Anim CID -->
                    </div>

                    <div class="column is-vcentered">
                        <h1>Entfaltung per App</h1>
                        <h3>
                            Weil sich heute einerseits alle Menschen vor die Aufgabe gestellt sehen, die Gestaltung
                            ihres Lebens selbst in die Hand zu nehmen und weil es andererseits dafür noch wenig
                            Vorbilder und geeignete Unterstützungssysteme gibt,
                            empfinden viele die Planung der eigenen Selbstentfaltung als eine echte Herausforderung. Dem
                            Entfalter geht's um deren Bewältigung.
                        </h3>
                    </div>
                </div>
            </div>
        </section>

        <section v-for="(p, index) in panels" class="panel" :class="`panel-${index} ${p.className}`" :key="index">

            <div v-if="p.className === 'left'" class="sheet">

                <div :class='"bgi __m bgi-" + (index + 1) + " is-0-tablet"'>
                    <img src="img/o_aside_left.svg" alt="">
                </div>

                <div class="txt left">
                    <h2><span>{{ p.title }}</span></h2>
                    <p class="content">
                        <audio-player :ref="`audio`" v-show="p.audio" :sound="p.audio" :autoplay="false" class="ap_inline" @audio-started="onAudio($event)"/>
                        <span v-html="p.content"></span>
                    </p>
                </div>
                <div :class='"bgi __t bgi-" + (index + 1) + " is-0-mobile"'>
                    <img src="img/o_aside_left.svg" alt="">
                </div>

            </div>


            <div v-if="p.className === 'right'" class="sheet">
                <div :class='"bgi bgi-" + (index + 1) '>
                    <img src="img/o_aside_left.svg" alt="">
                </div>
                <div class="txt left">
                    <h2><span>{{ p.title }}</span></h2>
                    <p class="content">
                        <audio-player :ref="`audio`" v-show="p.audio" :sound="p.audio" :autoplay="false" class="ap_inline" @audio-started="onAudio($event)"/>
                        <span v-html="p.content"></span></p>
                </div>
            </div>


        </section>

        <section class="section b_r_l left" style="background: white;">

            <div class="container">


                <div class="columns gap is-mobile is-multiline left">
                    <div class="column is-full-mobile">
                        <audio-player class="ap_inline" :sound="$utils.getSounds('home', 4)" :ref="'ap-4'" :autoplay="false" @audio-started="onAudioStarted(4)"></audio-player>
                        Den Entfalter zu durchlaufen, dauert ungefähr eine Stunde. Das Tool erklärt, fragt und ordnet – wie oben skizziert – Interessen, Kompetenzen und Weltbezüge und ermöglicht es so, sich selbst innerlich aufzuräumen und ein klares Bild von
                        sich zu entwickeln. Er macht einem die Fülle von Ideen und Einschätzungen, die man über sich selbst hat, bewusst und setzt sie zueinander in Beziehung. Der Entfalter ist dabei so genau, wie seine Nutzer*innen ehrlich und gewissenhaft sind
                        und spiegelt in geordneter Weise deren Einschätzungen und Prioritäten. So entsteht ein verständliches und lesbares Persönlichkeitsprofil, das online und als PDF verfügbar ist. Nutzt man mit dem Entfaltertool auch den Weg zum persönlichen
                        Entfaltungskonzept, kostet das mehr Zeit und zwar soviel mehr, wie man sich zum Überlegen und Abwägen seiner Entfaltungsvorstellungen nehmen möchte.
                    </div>
                    <div class="column is-full-mobile is-multiline">
                        <audio-player class="ap_inline" :sound="$utils.getSounds('home', 5)" :ref="'ap-5'" :autoplay="false" @audio-started="onAudioStarted(5)"></audio-player>
                        Der Entfalter ist universell angelegt und schafft deshalb in jeder Lebensphase dann mehr Klarheit, wenn man sich in Situationen der Selbstbesinnung und Entscheidungsvorbereitung befindet: Wenn man vor der Wahl für eine
                        Ausbildung, ein Studium oder eine Weiterbildung steht oder auf der Suche nach neuen Tätigkeitsfeldern oder gesellschaftlichen Engagements ist. Immer hilft der Entfalter seinen Nutzer*innen, die eigene Persönlichkeit besser zu verstehen und erlaubt, auf dieser Grundlage die Entscheidungen zu treffen, die wirklich passen können.
                    </div>
                </div>

                <div class="columns gap is-mobile is-multiline left is-vcentered">
                    <div class="column is-full-mobile">
                        <!-- <audio-player class="ap_inline" :sound="$utils.getSounds('home', 6)" :ref="'ap-6'" :autoplay="false" @audio-started="onAudioStarted(6)"></audio-player> -->
                        Der Entfalter wurde basierend auf entwicklungs- und bildungstheoretischer Forschung von Prof. Dr. Renate Girmes entwickelt. Es gibt frühere Versionen des Werkzeugs. Sie werden seit über 20 Jahren in der schulischen
                        Berufswahlunterstützung sowie in der Studienberatung und -begleitung eingesetzt. In dieser Zeit wurde das Tool immer mehr erweitert und verbessert.
                        Der Entfalter ist heute ein Produkt des Unternehmens OmniMundi, dessen Angebote das Ziel haben, Bildung und Ausbildung als ein aktives, sinnstiftendes „Sich-Bilden“ möglich zu machen. Mehr zum Unternehmen und seinen Produkten findet
                        sich unter <a href="https://www.omnimundi.de" target="_blank">https://www.omnimundi.de</a>
                    </div>

                </div>

            </div>

        </section>

        <svg  class="tri__down" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M0 0 L50 100 L100 0 Z" fill="#ffffff"></path>
        </svg>

    </div>


</template>
<script>
	import AudioPlayer from "../components/AudioPlayer";
	import AnimationBg from "../components/Animation-bg.component";
	export default {
		name: 'App',
		components: {AnimationBg, AudioPlayer},
		data() {
			return {
				panels: [
					{
						title: 'Willkommen beim Entfalter!',
						content: ` Herzlich Willkommen beim Entfalter – der App, die dabei hilft, sich selbst als Person differenziert zu sehen und so in der Welt, in Ausbildung, Beruf und Gesellschaft, die Aktivitäten und Plätze zu suchen und zu finden, die zu einem
                                passen könnten.
                                Der Entfalter ermöglicht es, sich selbst als Person möglichst gut kennen zu lernen und beschreiben zu können und dabei den eigenen Wünschen, Vorstellungen und Fähigkeiten auf die Spur zu kommen. Das passiert in einer strukturiert
                                angelegten Selbstbefragung, und zwar ohne dass (psychologische) Zuschreibungen vorgenommen werden. So entsteht ein Entfaltungsprofil. Mit diesem in der Hand lässt sich bereits viel gezielter klären, wie und wonach man suchen könnte
                                und sollte, um die Entwicklungsmöglichkeiten in der Welt anzusteuern, die einem aktuell Freude, Entfaltung/ Erweiterung, ja Erfüllung bieten können.`,
						className: 'left',
						bgColor: '#29b6f6',
						audio: this.$utils.getSounds('home',1)
					},
					{
						title: 'Auf dem Weg zum eigenen Persönlichkeits- und Entfaltungsprofil',
						content: `Wenn der Entfalter – im Gegensatz zu anderen Angeboten – nicht deutet und psychologisiert, was macht er dann? Der Entfalter führt mit Erklärungen und Fragen durch ganz verschiedene, berufs- und entwicklungsrelevante Fragens- und Themenbereiche. Er gibt einen Überblick darüber, welche Grundmöglichkeiten an Tätigkeiten, Rollen und Arbeitsumgebungen sich im Berufsleben, aber auch im Leben generell, eröffnen. Mit Hilfe von Fragen erlaubt er, sich über die
                                eigenen Interessen und Fähigkeiten, aber auch über Wünsche und die eigenen Präferenzen klar zu werden.
                                Mit seinen Klärungsherausforderungen nimmt der Entfalter seine Nutzer*innen also mit auf einen strukturierten Selbstentdeckungsweg. Der erlaubt es, die eigenen Fähigkeiten, Wünsche und Vorlieben systematisch anzusehen und die unterschiedlichen Ideen, die einem im Kopf herumschwirren, innerlich aufzuräumen und zu gewichten. Genau daraus ergibt sich dann ein detailreiches und individuelles Persönlichkeits- und Entfaltungssprofil.
                                <br/>
                                Wenn man das in der Hand hat, weiß man recht gut, mit welchem Potenzial an persönlichen Interessen und Kompetenzen man den Entwicklungsmöglichkeiten, Angeboten, aber auch Entscheidungsfragen gegenüber steht, die das Leben gerade
                                bietet. Ein derartig ‚aufgeräumtes’ Selbstbild schafft also die Basis für Entscheidungen, die wirklich zu einem passen können. Solche Passungen zu entdecken und zu prüfen, fällt jetzt leichter, weil man mit dem Entfalter auch ein Werkzeug
                                in der Hand hält, sich bietende Entfaltungsmöglichkeiten in Hinsicht auf darin steckende Aufgaben- und Rollenangebote sowie Welt- und Arbeitsbezüge zu analysieren und einzuschätzen.`,
						className: 'right',
						bgColor: '#ec407a',
						audio: this.$utils.getSounds('home',2)
					},
					{
						title: 'Vom persönlichen Profil zum Entfaltungskonzept',
						content: `Auch beim Einschätzen von Angeboten und Möglichkeiten merkt man: Es stecken in jedem Profil durchaus noch erweiternde Entfaltungsperspektiven und -möglichkeiten. Der Entfalter hilft auch dabei, diese ebenfalls zu entdecken und zu
                                durchdenken. Dazu kann man sich – nach und parallel zur Betrachtung seines Profils – auf den Weg zu (s)einem Entfaltungskonzept machen.
                                Dazu bietet der Entfalter zusätzlich an, die Fragen aus den vier Entfaltungsbereichen noch einmal durchzugehen, um zu prüfen, inwieweit die zuvor gegebenen Antworten dem entsprechen, was man sich auf mittlere bis langfristige Sicht
                                als sein Profil und als Entfaltung seiner Person vorstellt und wünscht. Summiert betrachtet ergibt sich aus diesem zweiten Durchgang ein Entfaltungskonzept. Das ist ein ‚Bild’, das zum Ausdruck bringt, was für einen einerseits mehr oder
                                weniger dringliche Entfaltungsfelder sind und wo man andererseits mit seinem aktuellen Entfaltungsprofil - jetzt und auf Dauer - bereits zufrieden ist oder im Moment jedenfalls keinen Handlungsbedarf sieht. Das vor Augen zu haben,
                                hilft wiederum, sich gezielt auf den Weg zu neuen Erfahrungen, Wissens- und Bildungsangeboten sowie Herausforderungen zu machen. Mit dem Entfaltungskonzept in der Hand gilt es also dann – für sich und im Gespräch mit anderen –nach solchen
                                Gelegenheiten Ausschau zu halten, bei denen bestehende und neue Interessen und Kompetenzen sich gezielt aus- und aufbauen lassen.`,
						className: 'left',
						bgColor: '#66bb6a',
						audio: this.$utils.getSounds('home',3)
					}
				]
			}
		},


		methods: {

			onAudio(currentAudio) {
				// Just stop all other instances
				// This is an universal method:
				// this.$store.commit('appstate/setAudioState', {stop: true, instance: instance._uid})
				// But this reliably works too:
				let ar = this.$refs;
				ar.audio.map((a) => {
					if (a !== currentAudio)
						a.pause()
				})
			},
			/**
			 * Start Playing (HACK)
			 */
			onAudioStarted(instance) {
				// Update store with new audiostate. The 'active' instance is this one, stop all others. (see audioComponent)
				// this.$store.commit('appstate/setAudioState', {stop: true, instance: instance._uid})
                this.onAudio(null)
				// Super-cheap coding:
				if (instance === 4) {
					this.$refs['ap-5'].pause()
					this.$refs['ap-6'].pause()
				}
				if (instance === 5) {
					this.$refs['ap-4'].pause()
					this.$refs['ap-6'].pause()
				}
				if (instance === 6) {
					this.$refs['ap-4'].pause()
					this.$refs['ap-5'].pause()
				}
			},
		}
	}
</script>
<style lang="scss" scoped>



    .bgi {
        &.bgi-1 {
            background-image : url('/img/slides/app/slide_0.jpg');
            .supports-webp & {
                 background-image: url('/img/slides/app/slide_0.jpg.webp');
            }
        }

        &.bgi-2 {
            background-image : url('/img/slides/app/slide_1.jpg');
            .supports-webp & {
                background-image: url('/img/slides/app/slide_1.jpg.webp');
            }
        }

        &.bgi-3 {
            background-image : url('/img/slides/app/slide_2.jpg');
            .supports-webp & {
                background-image: url('/img/slides/app/slide_2.jpg.webp');
            }
        }
    }

    .columns.gap .column {
        padding : 2rem;
    }

    .columns.gap-h .column {
        padding-left  : 2rem;
        padding-right : 2rem;
    }

    .b_r_l {
        background : white;

        h1 {
            margin : 0;
        }
    }


</style>

